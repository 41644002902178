import { ReactComponent as LogoType } from "../assets/images/logo-type.svg";

const Footer = () => {
  const getYear = () => {
    const d = new Date();
    let year = d.getFullYear();

    return year;
  };

  return (
    <div className="px-[25px] lg:px-[80px] bg-black text-green-light">
      <div className="flex site-max md:justify-between py-[20px] text-[18px] md:py-[35px] border-grey-light">
        <div className="flex text-center items-center">
          <div className="text-black bg-green-light p-[10px] w-[fit-content] rounded-full px-[10px]">
            <LogoType className="m-auto w-[50px]" />
          </div>
          <span className="pl-[10px] text-left">
            Copyright &copy; {getYear()} Eba wellness ltd. All rights reserved
          </span>
        </div>
        <ul className="md:flex space-x-[20px] items-center flex-row hidden w-fit">
          <a href="mailto:hello@ebaapp.com">
            <li>hello@ebaapp.com</li>
          </a>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
