import { ReactComponent as Logo } from "../assets/images/logo.svg";
import { ReactComponent as LogoType } from "../assets/images/logo-type.svg";

const Nav = () => {
  return (
    <div className="flex justify-between px-[25px] lg:px-[80px] items-center py-[40px] site-max">
      <div className="flex space-x-[9px]">
        <div className="w-[50px]">
          <Logo />
        </div>
        <div className="w-[50px] text-[#598246]">
          <LogoType />
        </div>
      </div>

      <a href="#banner" className="block md:hidden">
        <button className="bg-green-dark text-white px-[25px] py-[10px] text-[18px] rounded-full font-medium">
          Register
        </button>
      </a>

      <ul className="hidden md:flex items-center text-[18px] text-green-dark font-medium bg-[#EBFFE2] p-[5px] rounded-full border border-[#B7E2A3]">
        <a href="mailto:hello@ebaapp.com">
          <li className="px-[20px] cursor-pointer">Contact us</li>
        </a>
        <a href="#banner">
          <button className="bg-green-dark text-white px-[25px] py-[10px] text-[18px] rounded-full font-medium">
            Register
          </button>
        </a>
      </ul>
    </div>
  );
};

export default Nav;
