import "./App.css";
import Nav from "./components/nav";
import Hero from "./components/hero";
import Features from "./components/screens";
import Banner from "./components/footer-banner"
import Footer from "./components/footer"

function App() {
  return (
    <div className="App">
      <div className="bg-green-light">
        <Nav />
        <Hero />
        <Features />
        <Banner />
        <Footer />
      </div>
    </div>
  );
}

export default App;
