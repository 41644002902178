import React from "react";
import { ReactComponent as Banner } from "../assets/images/hero-illu.svg";

const Hero = () => {
  return (
    <div className="p-[25px] lg:p-[80px] site-max">
      <h1 className="text-[50px] md:text-[70px] lg:text-[90px] text-green-dark text-left leading-[67px] md:leading-[83px] lg:leading-[105px]">
        Conscious Banking <br className="hidden lg:block" /> for the modern
        generation
      </h1>

      <Banner className="lg:w-[85%] hidden md:block" />
      <img src="./banner.png" alt="Banner of use cases AI assistant, collaborative finance and Impactful donations" className="block md:hidden" />
    </div>
  );
};

export default Hero;
