import { ReactComponent as Stars } from "../assets/images/banner-img.svg";
import PocketBase from "pocketbase";
import { useState } from "react";

const Banner = () => {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const validateEmail = (item) => {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(item);
  }

  const signUp = async () => {
    const pbUrl = process.env.REACT_APP_PB;
    const pb = new PocketBase(pbUrl);
    const data = {
      email: email,
    };

    if (validateEmail(email)) {
    const record = await pb
      .collection("beta_signups")
      .create(data)
      .then((response) => {
        setSuccess("Thanks for signing up!");
        setError("");
        setEmail("");
      })
      .catch((error) => {
        if (error.status === 400) {
          setError("Looks like you've already signed up");
        } else {
          setError(error);
        }
        setSuccess("");
        console.log(error);
      });

      return record
    } else {
      setError("Please enter a valid email address")
    }
  };

  return (
    <div
      id="banner"
      className="md:text-black text-green-dark bg-white px-[30px] lg:px-[80px] site-max py-[30px]"
    >
      {success && (
        <div className="border-green font-medium bg-white slideDown text-[16px] bg-green-lighter border-solid border py-[13px] px-[20px] flex items-center space-x-[10px] z-50 m-auto rounded-full w-[fit-content] text-center text-green top-[10px] mt-[15px] right-0 left-0 fixed">
          {success}
        </div>
      )}
      {error && (
        <div className="border-red font-medium bg-white slideDown text-[16px] bg-green-lighter border-solid border py-[13px] px-[20px] flex items-center space-x-[10px] z-50 m-auto rounded-full w-[fit-content] text-center text-red top-[10px] mt-[15px] right-0 left-0 fixed">
          {error}
        </div>
      )}
      <div className="text-left flex justify-between w-full items-center">
        <div>
          <h2 className="text-[40px] md:text-[70px] leading-[53px] md:leading-[80px]">
            Banking with <br className="hidden md:block" /> a mission
          </h2>
          <p className="text-[25px] leading-[33px] md:leading-normal md:text-[30px] mt-[30px]">
            Experience a new kind of money management with{" "}
            <br className="hidden md:block" /> collaboration at the forefront
          </p>

          <div className="hidden lg:flex bg-[#EBFFE2] mt-[45px] border border-[#B7E2A3] w-full xl:w-[80%]  p-[5px] rounded-full">
            <input
              type="email"
              placeholder="Your email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="bg-transparent pl-[24px] outline-none text-[17px] w-full"
            />
            <button
              onClick={() => signUp()}
              className="bg-green-dark whitespace-nowrap text-[18px] text-white rounded-full px-[22px] py-[10px] font-medium"
            >
              Register for beta
            </button>
          </div>

          <div className="block lg:hidden">
            <div className="bg-[#EBFFE2] mt-[45px] py-[15px] border border-[#B7E2A3] w-full lg:w-fit p-[5px] rounded-full">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Your email address"
                className="bg-transparent pl-[24px] w-full outline-none text-[17px]"
              />
            </div>

            <button
              onClick={() => signUp()}
              className="mt-[17px] bg-green-dark text-[18px] float-right text-white rounded-full px-[22px] py-[10px] font-medium"
            >
              Register for beta
            </button>
          </div>
        </div>

        <Stars className="hidden w-full max-w-[430px] md:block" />
      </div>
    </div>
  );
};

export default Banner;
