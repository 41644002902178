import { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as Sheen } from "../assets/images/new.svg";
import { ReactComponent as Star } from "../assets/images/star-features.svg";
import { ReactComponent as MobStar } from "../assets/images/mob-stars.svg";

const Features = () => {
  const list = [
    {
      img: "/collab.png",
      title: "Collaborative Finance",
      text: "Level up your finances with our seamless joint accounts, shared budgeting tools, and innovative PayBack system. Stay in control with customizable notifications.",
      bg: "bg-green-dark",
    },
    {
      img: "/ai.png",
      title: "AI Assistant",
      compliance:
        "Our AI provides insights and potential advice, but we're not a financial advice service. Consult a qualified advisor for significant financial choices. We prioritize your financial well-being and follow strict compliance standards for data privacy.",
      text: "Your financial guru in your pocket! Automate paybacks, get the best savings strategies, and real-time insights. We're here to offer potential advice, but you're the boss of your money!",
      bg: "bg-midnight",
    },
    {
      img: "/impact.png",
      title: "Impactful Contributing",
      text: "Make a difference with your savings. Support vetted charities, track your impact, and explore socially responsible investing. The choice and responsibility are yours.",
      bg: "bg-green-dark",
    },
  ];

  const [feature, setFeature] = useState({
    img: list[0].img,
    text: list[0].text,
  });
  const [bg, setBg] = useState("bg-green-dark");
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    setShowImage(true);
  }, [feature]);

  const switchFeature = (feat, color, desc) => {
    setShowImage(false); // Reset showImage state
    setBg(color);
    setTimeout(() => {
      setFeature({ img: feat, text: desc });
    }, 300); // Delay the feature and bg state update to allow the fade-out animation to complete
  };

  return (
    <div className="px-[25px] pb-[33px] md:p-0">
      <div
        className={`${bg} md:py-[80px] pb-[100px] py-[25px] px-[25px] lg:px-[80px] transition ease-in-out delay-100 relative site-max rounded-[40px] md:rounded-none md:rounded-t-[70px]`}
      >
        <Star className="absolute hidden md:block " />
        <Star className="absolute bottom-0 right-[100px] hidden md:block " />
        <MobStar className="absolute block md:hidden w-[86px] bottom-[50%] left-0" />
        <MobStar className="absolute block md:hidden w-[180px] bottom-0 left-0 right-0 m-auto" />
        <MobStar className="absolute block md:hidden w-[86px] right-0 top-[15%]" />
        <div className="text-[30px] md:text-[60px] md:text-green-light md:px-[30px] text-green-light md:border border-green-light w-fit m-auto rounded-full py-[5px]">
          Let's break it down
        </div>
        <div className="md:flex md:space-x-12 lg:space-x-[200px] items-center justify-center  md:py-[100px]">
          <div className="md:hidden">
            <div className="flex flex-wrap justify-center mt-[10px] mb-[30px]">
              {list.map((item, index) => (
                <button
                  key={index}
                  onClick={() => switchFeature(item.img, item.bg, item.text)}
                  className={`${
                    feature.img === item.img
                      ? "text-green-dark  bg-green-light"
                      : "border border-green-light text-green-light"
                  } text-[14px] w-fit mb-[10px] ${
                    index === 0 && "mr-[10px]"
                  } px-[14px] py-[7px] rounded-full`}
                >
                  {item.title}
                </button>
              ))}
            </div>

            <p className="text-[14px] text-green-light text-left mb-[45px]">
              {feature.text}
            </p>
          </div>

          <CSSTransition
            key={feature.img}
            in={showImage}
            timeout={300}
            classNames="fade"
          >
            <div
              className={`w-fit relative ${
                feature.img === "/ai.png" ? "text-white" : "text-[#97FF66]"
              }`}
              style={{ margin: "0 auto" }}
            >
              <Sheen className="absolute bottom-[-10px] spin w-[35px]" />
              <Sheen className="absolute top-[-10px] spin spin w-[35px]" />
              <Sheen className="absolute top-[30%] spin right-[-10px] spin w-[25px]" />
              {feature ? (
                <img
                  alt={feature.text}
                  className="max-w-[250px] md:max-w-[300px] lg:max-w-[380px]"
                  src={feature.img}
                />
              ) : (
                <div className="md:w-[380px] md:h-[800px]"></div>
              )}
            </div>
          </CSSTransition>

          <div
            className="hidden md:flex relative z-10 flex-col space-y-[50px]"
            style={{ margin: "0 auto" }}
          >
            {list.map((item, index) => (
              <div
                key={index}
                className="md:ml-[50px] xl:ml-0 text-green-light text-left text-[18px]"
              >
                <button
                  onClick={() => switchFeature(item.img, item.bg)}
                  className={`${
                    feature.img === item.img
                      ? "text-green bg-green-light"
                      : "border border-green-light"
                  } px-[13px] mb-[24px] py-[9px] rounded-full transition ease-in-out`}
                >
                  {item.title}
                </button>
                <p className="max-w-[550px]">
                  {item.text}
                  {item.compliance && feature.img === item.img && (
                    <span className="block mt-[30px] text-green-light text-[12px] bg-transparent py-[10px] rounded-[8px]">
                      {item.compliance}
                    </span>
                  )}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
